import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"white","display":"flex","flex-direction":"column","min-height":"calc(100vh - 80px)"} }
const _hoisted_2 = {
  class: "bg-cs_background w-100 d-flex flex-column",
  style: {"display":"flex","flex-direction":"column","flex":"1"}
}

import "@/plugins/gsap";

import { onBeforeMount, onMounted, onUnmounted } from "vue";
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from "@unhead/vue";
import { i18n } from "./plugins/i18n";
import imgOne from "@/assets/logo_mobile.png";
import { useTheme } from "vuetify";

import {
  customEvents,
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { dispatchConfigEvent } from "./utils/loadTopMenu";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const headInfo = useHead({
  title: "One",
  link: [{ rel: "icon", href: imgOne, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const theme = useTheme();

function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
  const themeName = event.detail;
  theme.global.name.value = themeName;
}

function changeLanguageOnEvent(event: CustomEvent<LANGUAGE_CHANGED_PAYLOAD>) {
  const language = event.detail;
  i18n.global.locale.value = language;
}

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }

  const storeLanguage = localStorage.getItem("language");
  if (storeLanguage && storeLanguage?.length > 0) {
    i18n.global.locale.value = storeLanguage;
  }
});

onMounted(async () => {
  dispatchConfigEvent();
  customEvents.themeEvents.listener(changeThemeOnEvent);
  customEvents.languageEvent.listener(changeLanguageOnEvent);
});

onUnmounted(() => {
  customEvents.languageEvent.unsubscribe();
  customEvents.themeEvents.unsubscribe();
  headInfo.dispose();
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_v_theme_provider = _resolveComponent("v-theme-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_theme_provider, { class: "d-flex" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RouterView)
        ])
      ]),
      _: 1
    })
  ]))
}
}

})