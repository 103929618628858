<template>
  <div v-if="isAuthenticated" style="min-height: calc(100vh - 80px)">
    <RouterView> </RouterView>
  </div>
</template>

<script setup lang="ts">
import { lib } from "@clearsale/one-lib-auth";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const isAuthenticated = ref(false);
const router = useRouter();
const activeSSO = process.env.VUE_APP_ENV !== "development";
onMounted(() => {
  if (!activeSSO) {
    isAuthenticated.value = true;
    return;
  }

  if (lib.Authenticate.validateSession("one")) {
    isAuthenticated.value = true;
    return;
  }
  router.push({ name: "Auth" });
});
</script>
