<template>
  <div
    :data-testid="generateTestId(CustomHeroElements.Content)"
    id="hero"
    :style="{
      backgroundImage: mdAndUp ? `url('${srcImage}')` : `url('${srcImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'center',
      padding: `${mdAndUp ? '0rem 1.5rem 0rem 1.5rem' : '0rem 0.75rem 0rem 0.75rem'}`,
      height,
      width,
    }"
    class="title h-100 w-100 position-relative"
  >
    <slot>
      <h1
        :style="{
          fontSize: mdAndUp ? 'var(--v-typescale-display-small-size)' : 'var(--v-typescale-display-large-size)',
          lineHeight: mdAndUp ? 'var(--v-typescale-display-large-line-height)' : 'var(--v-typescale-display-large-size)',
        }"
      >
        {{ label }}
      </h1>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";
const { mdAndUp } = useDisplay();

defineProps<{
  label?: string;
  srcImage?: string;
  width?: string;
  height?: string;
}>();
</script>

<style scoped lang="scss">
.title {
  width: 100%;
  box-sizing: border-box;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  border-radius: 28px;

  display: flex;

  justify-content: space-between;
  align-items: flex-end;

  & h1 {
    margin: 0;
    font-size: var(--v-typescale-display-large-size);
    font-weight: 400;
  }

  & .status-cards {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    gap: 0.5rem;

    & h2 {
      font-size: var(--v-typescale-display-small-size);
      font-weight: 400;
    }

    .v-card-text {
      padding: 0;
      flex: 0;

      & > span {
        font-size: var(--v-typescale-body-medium-size);

        & v-icon {
          font-size: 0.5rem;
        }
      }

      & > p {
        margin: 0;
        font-size: var(--v-typescale-headline-medium-size);
        font-weight: 400;
        color: black;

        & span {
          font-size: var(--v-typescale-body-medium-size);
          font-weight: 500;
        }
      }
    }
  }
}
</style>
