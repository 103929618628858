<template><div></div></template>

<script setup lang="ts">
import { routeNames } from "@/router";
import { auth } from "@/services/auth";
import { onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const activeSSO = process.env.VUE_APP_ENV !== "development";
onMounted(async () => {
  if (activeSSO) {
    auth.execute(async () => {
      if (window.location.href.includes("/app/auth")) {
        const timeout = setTimeout(() => {
          router.replace({ name: routeNames.oneView });
          clearTimeout(timeout);
        }, 100);
      }
    });
    return;
  }
  router.replace({ name: routeNames.oneView });
});
</script>
