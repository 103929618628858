export const label = {
  AppsPage: {
    Hero: {
      topMessage: "label.AppsPage.Hero.topMessage",
      title: "label.AppsPage.Hero.title",
      subtitle: "label.AppsPage.Hero.subtitle"
    },
    Apps: {
      title: "label.AppsPage.Apps.title"
    }
  },
  SearchBar: {
    placeholder: "label.SearchBar.placeholder"
  },
  UserMenu: {
    language: {
      title: "label.UserMenu.language.title",
      english: "label.UserMenu.language.english",
      portuguese: "label.UserMenu.language.portuguese",
      spanish: "label.UserMenu.language.spanish"
    },
    timezone: "label.UserMenu.timezone",
    theme: {
      title: "label.UserMenu.theme.title",
      dark: "label.UserMenu.theme.dark",
      light: "label.UserMenu.theme.light"
    },
    signout: "label.UserMenu.signout"
  },
  AppCard: {
    Shield: {
      subtitle: "label.AppCard.Shield.subtitle"
    },
    Nexus: {
      subtitle: "label.AppCard.Nexus.subtitle"
    }
  },
}
