import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import ComponentPreview from '../views/ComponentPreview.vue'
import OneView from '@/views/OneView.vue'
import AuthView from '@/views/AuthView.vue'
import AuthenticatedLayout from '@/layout/AuthenticatedLayout.vue'

export const routeNames = {
  oneView: 'OneView',
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: { template: '', beforeCreate() { this.$router.push({ name: routeNames.oneView }) } }
  },
  {
    path: '/app',
    component: AuthenticatedLayout,
    children: [
      {
        path: '',
        component: OneView,
        name: routeNames.oneView,
      }
    ]
  },
  {
    path: '/app/auth',
    component: AuthView,
    name: 'Auth'
  },
  {
    path: '/app/preview',
    component: ComponentPreview
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
