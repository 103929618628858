<template>
  <CustomHero :src-image="themeName === 'light' ? imgHeroLight : imgHeroDark">
    <div class="d-flex align-center" style="height: 300px">
      <div :class="mdAndUp ? 'px-6' : 'px-2'">
        <div class="text-tertiary hero__header_text pb-2">{{ t(label.AppsPage.Hero.topMessage) }}</div>
        <div class="hero__middle_text text-cs_on_surface">{{ t(label.AppsPage.Hero.title) }}</div>
        <div class="hero__bottom_text text-cs_on_surface"
          :style="{
            width: mdAndUp ? '500px' : '200px',
          }"
        >
        {{ t(label.AppsPage.Hero.subtitle) }}
        </div>
      </div>
    </div>
  </CustomHero>
</template>

<script setup lang="ts">
import imgHeroLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgHeroDark from "@/assets/backgrounds/transaction-desktop-dark.png";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import { useDisplay,useTheme } from "vuetify/lib/framework.mjs";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";

const { t } = useI18n();
const { name: themeName } = useTheme();
const { mdAndUp } = useDisplay();
</script>

<style lang="scss" scoped>
.hero__ {
  &header_text {
    font-size: var(--v-typescale-body-large-size);
    line-height: var(--v-typescale-body-large-line-height);
  }

  &middle_text {
    font-size: var(--v-typescale-display-large-size);
    line-height: var(--v-typescale-display-large-line-height);
  }

  &bottom_text {
    font-size: var(--v-typescale-title-large-size);
    line-height: var(--v-typescale-title-large-line-height);
  }
}

@media (max-width: 840px) {
  .hero__ {
    &header_text {
      font-size: var(--v-typescale-body-medium-size);
      line-height: var(--v-typescale-body-medium-line-height);
    }

    &middle_text {
      font-size: var(--v-typescale-display-medium-size);
      line-height: var(--v-typescale-display-medium-line-height);
    }

    &bottom_text {
      font-size: var(--v-typescale-body-large-size);
      line-height: var(--v-typescale-body-large-line-height);
    }
  }
}
</style>
