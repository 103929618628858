import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { routeNames } from "@/router";
import { auth } from "@/services/auth";
import { onMounted } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthView',
  setup(__props) {

const router = useRouter();
const activeSSO = process.env.VUE_APP_ENV !== "development";
onMounted(async () => {
  if (activeSSO) {
    auth.execute(async () => {
      if (window.location.href.includes("/app/auth")) {
        const timeout = setTimeout(() => {
          router.replace({ name: routeNames.oneView });
          clearTimeout(timeout);
        }, 100);
      }
    });
    return;
  }
  router.replace({ name: routeNames.oneView });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})