export const label = {
  AppsPage: {
    Hero: {
      topMessage: "One step ahead_",
      title: "One ClearSale",
      subtitle: "Todas as suas soluções em proteção contra fraudes em um único lugar"
    },
    Apps: {
      title: "Seus apps"
    }
  },
  SearchBar: {
    placeholder: "Pesquisar na ClearSale"
  },
  UserMenu: {
    language: {
      title: "Idioma",
      english: "English",
      portuguese: "Português",
      spanish: "Español"
    },
    timezone: "Fuso horário",
    theme: {
      title: "Tema",
      dark: "Escuro",
      light: "Claro"
    },
    signout: "Sair"
  },
  AppCard: {
    Shield: {
      subtitle: "Inteligência na análise de fraude"
    },
    Nexus: {
      subtitle: "Visualização da análise de fraude"
    }

  },
}
