import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  style: {"min-height":"calc(100vh - 80px)","display":"flex","flex-direction":"column","max-width":"1040px"},
  class: "mx-auto flex-1"
}
const _hoisted_2 = { style: {"flex":"1","display":"flex","flex-direction":"column"} }
const _hoisted_3 = {
  class: "d-flex flex-column",
  style: {"flex-grow":"1"}
}
const _hoisted_4 = { class: "apps__title_text ml-8 mt-8" }
const _hoisted_5 = {
  class: "d-flex overflow-auto",
  style: {"flex":"1"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  class: "mt-4",
  style: {"display":"flex","justify-content":"center","align-items":"center","padding":"0.5rem 0rem"}
}
const _hoisted_8 = ["src"]

import { computed, onMounted, onUnmounted, ref } from "vue";

import imgFooter from "@/assets/logo_mobile.png";
import HeroOne from "@/components/one/HeroOne/HeroOne.vue";
import CardOneApp from "@/components/one/CardOneApp/CardOneApp.vue";
import { useOneStore } from "@/store/OneStore";
import { useRouter } from "vue-router";
import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";

import NexusViewDarkBG from "@/assets/backgrounds/BG-nexus-dark.jpg";
import NexusViewLightBG from "@/assets/backgrounds/BG-nexus-light.jpg";
import ShieldViewDarkBG from "@/assets/backgrounds/BG-shield-dark.jpg";
import ShieldViewLightBG from "@/assets/backgrounds/BG-shield-light.jpg";
import NexusLogoWhite from "@/assets/icons/nexus-logo-white.svg";
import ShieldLogoWhite from "@/assets/icons/shield-logo-white.svg";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";
import { getApps } from "@/api/resources/oneResource";
import NexusLogo from "@/assets/logos/logo_nexus.svg";
import ShieldLogo from "@/assets/logos/logo_shield.svg";
import { dispatchConfigEvent } from "@/utils/loadTopMenu";

type AppRender = {
  routerName: string;
  label: keyof typeof label.AppCard;
};

type Theme_Type = "light" | "dark";

type AppView_Type = "NexusView" | "ShieldView";

type SelectBackground = {
  [ThemeKey in Theme_Type]: {
    [AppViewKey in AppView_Type]: string;
  };
};

type SelectRouter = {
  [appKey in AppView_Type]: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'OneView',
  setup(__props) {

const { t } = useI18n();
const { $state } = useOneStore();
const router = useRouter();
const { mdAndUp } = useDisplay();
const { name: themeName } = useTheme();

const appIcons = {
  ShieldView: ShieldLogoWhite,
  NexusView: NexusLogoWhite,
};

const routerMap: SelectRouter = {
  NexusView: "/nexus",
  ShieldView: "/shield",
};

const selectBackgroundByTheme: SelectBackground = {
  light: {
    NexusView: NexusViewLightBG,
    ShieldView: ShieldViewLightBG,
  },
  dark: {
    NexusView: NexusViewDarkBG,
    ShieldView: ShieldViewDarkBG,
  },
};

const scrollContainer = ref<HTMLElement | null>(null);
const isDragging = ref(false);
let mouseIsDown = false;
let startX: number;
let scrollLeft: number;

const renderApps = computed(() => {
  return $state.apps.filter((app) => app.routerName !== "OneView");
});

const handleMouseDown = (e: MouseEvent) => {
  mouseIsDown = true;
  isDragging.value = false;
  if (!scrollContainer.value) return;

  startX = e.pageX - scrollContainer.value.offsetLeft;
  scrollLeft = scrollContainer.value.scrollLeft;
};

const handleMouseLeave = () => {
  mouseIsDown = false;

  if (!scrollContainer.value) return;

  scrollContainer.value.classList.remove("active");
};

const handleMouseUp = () => {
  mouseIsDown = false;

  if (!scrollContainer.value) return;

  scrollContainer.value.classList.remove("active");
};

const handleMouseMove = (e: MouseEvent) => {
  if (!mouseIsDown) return;
  e.preventDefault();

  if (!scrollContainer.value) return;

  scrollContainer.value.classList.add("active");
  const xOffsetContainer = e.pageX - scrollContainer.value.offsetLeft;
  const walk = (xOffsetContainer - startX) * 1;
  scrollContainer.value.scrollLeft = scrollLeft - walk;
  isDragging.value = true;
};

const addEventListeners = () => {
  const container = scrollContainer.value;

  if (!container) return;

  container.addEventListener("mousedown", handleMouseDown);
  container.addEventListener("mouseleave", handleMouseLeave);
  container.addEventListener("mouseup", handleMouseUp);
  container.addEventListener("mousemove", handleMouseMove);
};

const removeEventListeners = () => {
  const container = scrollContainer.value;

  if (!container) return;

  container.removeEventListener("mousedown", handleMouseDown);
  container.removeEventListener("mouseleave", handleMouseLeave);
  container.removeEventListener("mouseup", handleMouseUp);
  container.removeEventListener("mousemove", handleMouseMove);
};

onMounted(() => {
  dispatchConfigEvent();
});

loadAppsIsShow();
addEventListeners();

onUnmounted(() => {
  removeEventListeners();
});

function handleClick(item: any, event: MouseEvent) {
  if (isDragging.value) {
    event.preventDefault();
    event.stopImmediatePropagation();
  } else {
    if (item.routerName)
      router.push(routerMap[item.routerName as AppView_Type]);
  }
}

const allApps = [
  {
    id: "Apps",
    label: "One",
    routerName: "OneView",
    order: 1,
    subLinks: [],
    description: "",
  },
  {
    id: "Apps",
    label: "Shield",
    routerName: "ShieldView",
    order: 2,
    img: ShieldLogo,
    subLinks: [],
    description: t(label.AppCard.Shield.subtitle),
  },
  {
    id: "Apps",
    label: "Nexus",
    routerName: "NexusView",
    order: 3,
    img: NexusLogo,
    subLinks: [],
    description: t(label.AppCard.Nexus.subtitle),
  },
];

const { loadApps } = useOneStore();

function loadAppsIsShow() {
  getApps().then((res) => {
    const appsAllowed =
      res.results.length > 0
        ? res.results
            .filter((app) => app !== "app")
            .map((app) => {
              return allApps.find((appItem) => appItem.label === app);
            })
        : [];

    loadApps(appsAllowed);
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'px-4': !_unref(mdAndUp) })
    }, [
      _createVNode(HeroOne)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)(_unref(label).AppsPage.Apps.title)), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "d-flex overflow-x-auto mt-4 flex-nowrap hide-scrollbar pr-4",
              style: _normalizeStyle([{
                gap: _unref(mdAndUp) ? '1.5rem' : '1rem',
              }, {"max-width":"1040px","overflow":"hidden"}]),
              ref_key: "scrollContainer",
              ref: scrollContainer
            }, [
              (!_unref(mdAndUp))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderApps.value as AppRender[], (app) => {
                return (_openBlock(), _createBlock(CardOneApp, {
                  key: app.routerName,
                  app: {
                  ...app,
                  description: _unref(t)(_unref(label).AppCard[app.label].subtitle),
                },
                  icon: appIcons[app.routerName as keyof typeof appIcons],
                  onClick: _withModifiers(($event: any) => (handleClick(app, $event)), ["stop"]),
                  img: selectBackgroundByTheme[_unref(themeName) as Theme_Type][app.routerName as AppView_Type] 
                }, null, 8, ["app", "icon", "onClick", "img"]))
              }), 128))
            ], 4)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          style: {"width":"24px"},
          src: _unref(imgFooter),
          alt: "imgClearsaleOptions.alt"
        }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}
}

})