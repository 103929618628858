import { h, createApp } from "vue";
import singleSpaVue from "single-spa-vue";
import "@clearsale/material-ds/dist/clearsale-material-ds/clearsale-material-ds.css";
// import { defineCustomElements } from '@clearsale/material-ds/dist/loader'

import vuetify from "@/plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { pinia } from "./plugins/store";

import App from "./App.vue";
import router from "./router";
import { handlersHTTP } from "./api/mocks/server/handlersHTTP";
import { createHead } from "@unhead/vue";
import { i18n } from "./plugins/i18n";

loadFonts();

const head = createHead();

// defineCustomElements(window);

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  handleInstance(app) {
    app.use(head);
    app.use(i18n);
    app.use(vuetify);
    app.use(pinia);
    app.use(router);
  },
});

export const bootstrap = async () => {
  return vueLifecycles.bootstrap;
};
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

function dispatchCustomEventLoadMockAPI() {
  const event = new CustomEvent("loadMockAPI", {
    detail: {
      handlers: handlersHTTP,
    },
  });

  window.dispatchEvent(event);
}

dispatchCustomEventLoadMockAPI();
