import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-testid"]

import { useDisplay } from "vuetify";
import { generateTestId } from "@/helpers/generateTestId";
import { CustomHeroElements } from "./constants/CustomHeroElements";

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomHero',
  props: {
    label: {},
    srcImage: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const { mdAndUp } = useDisplay();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": _unref(generateTestId)(_unref(CustomHeroElements).Content),
    id: "hero",
    style: _normalizeStyle({
      backgroundImage: _unref(mdAndUp) ? `url('${_ctx.srcImage}')` : `url('${_ctx.srcImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'center',
      padding: `${_unref(mdAndUp) ? '0rem 1.5rem 0rem 1.5rem' : '0rem 0.75rem 0rem 0.75rem'}`,
      height: _ctx.height,
      width: _ctx.width,
    }),
    class: "title h-100 w-100 position-relative"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("h1", {
        style: _normalizeStyle({
          fontSize: _unref(mdAndUp) ? 'var(--v-typescale-display-small-size)' : 'var(--v-typescale-display-large-size)',
          lineHeight: _unref(mdAndUp) ? 'var(--v-typescale-display-large-line-height)' : 'var(--v-typescale-display-large-size)',
        })
      }, _toDisplayString(_ctx.label), 5)
    ])
  ], 12, _hoisted_1))
}
}

})