import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"min-height":"calc(100vh - 80px)"}
}

import { lib } from "@clearsale/one-lib-auth";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthenticatedLayout',
  setup(__props) {

const isAuthenticated = ref(false);
const router = useRouter();
const activeSSO = process.env.VUE_APP_ENV !== "development";
onMounted(() => {
  if (!activeSSO) {
    isAuthenticated.value = true;
    return;
  }

  if (lib.Authenticate.validateSession("one")) {
    isAuthenticated.value = true;
    return;
  }
  router.push({ name: "Auth" });
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (isAuthenticated.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_RouterView)
      ]))
    : _createCommentVNode("", true)
}
}

})