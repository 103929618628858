export const label = {
  AppsPage: {
    Hero: {
      topMessage: "One step ahead_",
      title: "One ClearSale",
      subtitle: "Todas sus soluciones de protección contra fraude en un solo lugar"
    },
    Apps: {
      title: "Tus apps"
    }
  },
  SearchBar: {
    placeholder: "Buscar en ClearSale"
  },
  UserMenu: {
    language: {
      title: "Idioma",
      english: "English",
      portuguese: "Português",
      spanish: "Español"
    },
    timezone: "Zona horaria",
    theme: {
      title: "Tema",
      dark: "Oscuro",
      light: "Claro"
    },
    signout: "Desconectar"
  },
  AppCard: {
    Shield: {
      subtitle: "Inteligencia en análisis de fraude"
    },
    Nexus: {
      subtitle: "Visualización de análisis de fraude"
    }
  }
}
