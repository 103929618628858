import { lib } from "@clearsale/one-lib-auth";

const params = {
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID ?? "e0fb5011-d731-4ab2-aa5c-d0e523174a86",
  redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI ?? "http://localhost:9000/app",
  appName: "one",
  authFlow: process.env.VUE_APP_AUTH_FLOW ?? '',
  authTenant: process.env.VUE_APP_AUTH_TENANT ?? '',
  authUrl: process.env.VUE_APP_AUTH_URL ?? '',
  redirectUriOne: process.env.VUE_APP_AUTH_REDIRECT_URI_ONE ?? '',
};

export const auth = new lib.Authenticate(params);
