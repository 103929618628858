<template>
  <div
    style="
      background-color: white;
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 80px);
    "
  >
    <v-theme-provider class="d-flex">
      <div
        class="bg-cs_background w-100 d-flex flex-column"
        style="display: flex; flex-direction: column; flex: 1"
      >
        <RouterView />
      </div>
    </v-theme-provider>
  </div>
</template>

<script lang="ts" setup>
import "@/plugins/gsap";

import { onBeforeMount, onMounted, onUnmounted } from "vue";
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from "@unhead/vue";
import { i18n } from "./plugins/i18n";
import imgOne from "@/assets/logo_mobile.png";
import { useTheme } from "vuetify";

import {
  customEvents,
  LANGUAGE_CHANGED_PAYLOAD,
  THEME_CHANGED_PAYLOAD,
} from "@clearsale/one-lib-events";
import { dispatchConfigEvent } from "./utils/loadTopMenu";

const headInfo = useHead({
  title: "One",
  link: [{ rel: "icon", href: imgOne, type: "image/svg+xml" }],
}) as ActiveHeadEntry<UseHeadInput<MergeHead>>;

const theme = useTheme();

function changeThemeOnEvent(event: CustomEvent<THEME_CHANGED_PAYLOAD>) {
  const themeName = event.detail;
  theme.global.name.value = themeName;
}

function changeLanguageOnEvent(event: CustomEvent<LANGUAGE_CHANGED_PAYLOAD>) {
  const language = event.detail;
  i18n.global.locale.value = language;
}

onBeforeMount(() => {
  const storeThemeName = localStorage.getItem("theme");
  if (storeThemeName && storeThemeName?.length > 0) {
    theme.global.name.value = storeThemeName;
  }

  const storeLanguage = localStorage.getItem("language");
  if (storeLanguage && storeLanguage?.length > 0) {
    i18n.global.locale.value = storeLanguage;
  }
});

onMounted(async () => {
  dispatchConfigEvent();
  customEvents.themeEvents.listener(changeThemeOnEvent);
  customEvents.languageEvent.listener(changeLanguageOnEvent);
});

onUnmounted(() => {
  customEvents.languageEvent.unsubscribe();
  customEvents.themeEvents.unsubscribe();
  headInfo.dispose();
});
</script>
