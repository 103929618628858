import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex align-center",
  style: {"height":"300px"}
}
const _hoisted_2 = { class: "text-tertiary hero__header_text pb-2" }
const _hoisted_3 = { class: "hero__middle_text text-cs_on_surface" }

import imgHeroLight from "@/assets/backgrounds/transaction-desktop-light.png";
import imgHeroDark from "@/assets/backgrounds/transaction-desktop-dark.png";
import CustomHero from "@/components/shared/CustomHero/CustomHero.vue";
import { useDisplay,useTheme } from "vuetify/lib/framework.mjs";
import { useI18n } from "vue-i18n";
import { label } from "@/plugins/locales/global";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeroOne',
  setup(__props) {

const { t } = useI18n();
const { name: themeName } = useTheme();
const { mdAndUp } = useDisplay();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CustomHero, {
    "src-image": _unref(themeName) === 'light' ? _unref(imgHeroLight) : _unref(imgHeroDark)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(_unref(mdAndUp) ? 'px-6' : 'px-2')
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)(_unref(label).AppsPage.Hero.topMessage)), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)(_unref(label).AppsPage.Hero.title)), 1),
          _createElementVNode("div", {
            class: "hero__bottom_text text-cs_on_surface",
            style: _normalizeStyle({
            width: _unref(mdAndUp) ? '500px' : '200px',
          })
          }, _toDisplayString(_unref(t)(_unref(label).AppsPage.Hero.subtitle)), 5)
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["src-image"]))
}
}

})